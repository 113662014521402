import React from 'react';
import PropTypes from 'prop-types';
import { get } from '../../lib/nodash';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { navigate } from 'gatsby';
import { parse } from 'query-string';
import { Box } from 'grommet';

import { CONTENT_NODE } from '../../queries/storyblokQueries';
import { draftStoryblokClient } from '../../gatsby-theme-apollo/client';
import { setActiveModal } from '../../state/ui/uiSlice';
import { track } from '../../lib/analytics';
import { PROMOTION_VIEWED } from '../../lib/analytics/segmentActions';

const CampaignRoute = ({ location }) => {
  const dispatch = useDispatch();
  const params = parse(location.search);

  useQuery(CONTENT_NODE, {
    client: draftStoryblokClient,
    variables: {
      id: location.pathname.substring(1),
    },
    onCompleted: (d) => {
      const story = get('ContentNode', d);

      if (story) {
        if (params._storyblok) {
          dispatch(
            setActiveModal({
              type: 'component',
              value: 'campaign',
              meta: { story, utmSource: story.content.utmSource },
            })
          );
        } else {
          track(PROMOTION_VIEWED, {
            promotion_id: story.content.utmSource,
            name: story.content.title,
          });
          navigate(`/?utm_source=${story.content.utmSource}`);
        }
      } else {
        navigate('/');
      }
    },
  });
  return <Box fill height={{ min: '80vh' }} />;
};

CampaignRoute.propTypes = {
  slug: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

export default CampaignRoute;
